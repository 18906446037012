import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Container,
  Paper,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderIcon from '@mui/icons-material/Folder';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import getMPTheme from './theme/getSignInSideTheme';
import { PaletteMode, ThemeProvider, createTheme } from '@mui/material/styles';
import WorkIcon from '@mui/icons-material/Work';
import MessageIcon from '@mui/icons-material/Message';
import { Navigate, useNavigate } from 'react-router-dom';

const API_BASE_URL = 'https://backend-summer-butterfly-3214.fly.dev';

const createAxiosInstance = (token = null, contentType = 'application/json') => {
  const config = {
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': contentType,
    },
    withCredentials: true
  };
  
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  
  return axios.create(config);
};

const drawerWidth = 240;

const FolderItem = () => {
  const { folderId } = useParams();
  const [files, setFiles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const userId = localStorage.getItem('userId');
  const [mode, setMode] = React.useState('light');
  const MPTheme = createTheme(getMPTheme(mode));
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }
    fetchFiles();
    fetchPermissions();
  }, [folderId]);

  const fetchFiles = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken || !folderId) return;

      const api = createAxiosInstance(accessToken);
      api.defaults.headers['User-Id'] = userId;

      const response = await api.get(`/api/folders/${folderId}/files`);
      
      const filesWithUrls = await Promise.all(
        response.data.map(async (file) => {
          const fileResponse = await api.get(`/api/files/${file._id}`, {
            params: { user_id: userId }
          });
          return { ...fileResponse.data.metadata, download_url: fileResponse.data.download_url };
        })
      );
      setFiles(filesWithUrls);
    } catch (error) {
      console.error('Error fetching files:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
    }
  };

  const fetchPermissions = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken || !folderId) return;

      const api = createAxiosInstance(accessToken);
      const response = await api.get(`/api/folders/${folderId}/permissions`);
      
      setPermissions(response.data.filter(permission => permission.user_id !== userId));
    } catch (error) {
      console.error('Error fetching permissions:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files?.[0];
    if (file && folderId) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) return;

        const api = createAxiosInstance(accessToken, 'multipart/form-data');
        const response = await api.post(`/api/folders/${folderId}/files`, formData);
        
        console.log('File uploaded:', response.data);
        fetchFiles();
      } catch (error) {
        console.error('Error uploading file:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        }
      }
    }
  };

  const handleRemovePermission = async (userIdToRemove) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken || !folderId) return;

      const api = createAxiosInstance(accessToken);
      await api.delete(`/api/folders/${folderId}/permissions/${userIdToRemove}`);
      
      fetchPermissions();
    } catch (error) {
      console.error('Error removing permission:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
    }
  };

  const handleDeleteFile = async (fileId) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken || !folderId) return;

      const api = createAxiosInstance(accessToken);
      await api.delete(`/api/folders/${folderId}/files/${fileId}`);
      
      fetchFiles();
    } catch (error) {
      console.error('Error deleting file:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
    }
  };

  const renderFilePreview = (file) => {
    const fileExtension = file.filename.split('.').pop()?.toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
      return <img src={file.download_url} alt={file.filename} style={{ maxWidth: '100%', maxHeight: '200px' }} />;
    } else if (['mp4', 'webm'].includes(fileExtension)) {
      return (
        <video controls style={{ maxWidth: '100%', maxHeight: '200px' }}>
          <source src={file.download_url} type={file.content_type} />
        </video>
      );
    } else {
      return <InsertDriveFileIcon fontSize="large" />;
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {[
          { text: 'Dashboard', icon: <DashboardIcon />, route: '/dashboard' },
          { text: 'Jobs', icon: <WorkIcon />, route: '/jobs' },
          { text: 'Job Post', icon: <WorkIcon />, route: '/job-post' },

          { text: 'Messages', icon: <MessageIcon />, route: '/messaging' },
          { text: 'Folders', icon: <FolderIcon />, route: '/folders' },
        ].map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton onClick={() => handleNavigation(item.route)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Folder: {folderId}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" gutterBottom>
                    Upload File
                  </Typography>
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<FileUploadIcon />}
                  >
                    Upload File
                    <input
                      type="file"
                      hidden
                      onChange={handleFileUpload}
                    />
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" gutterBottom>
                    Files
                  </Typography>
                  <Grid container spacing={2}>
                    {files.map((file) => (
                      <Grid item xs={12} sm={6} md={4} key={file._id}>
                        <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
                          {renderFilePreview(file)}
                          <Typography variant="subtitle1" sx={{ mt: 1 }}>
                            {file.filename}
                          </Typography>
                          <Button
                            variant="outlined"
                            href={file.download_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Download
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleDeleteFile(file._id)}
                            sx={{ mt: 1 }}
                          >
                            Delete
                          </Button>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" gutterBottom>
                    Folder Permissions
                  </Typography>
                  {permissions.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {permissions.map((permission) => (
                            <TableRow key={permission.user_id}>
                              <TableCell>
                                <Link
                                  to={`/dm/${permission.user_id}`}
                                  style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                  {permission.username}
                                </Link>
                              </TableCell>
                              <TableCell>{permission.role}</TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() => handleRemovePermission(permission.user_id)}
                                  disabled={permission.role === 'owner'}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography>No other users have access to this folder.</Typography>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FolderItem;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { io } from 'socket.io-client';
import {
  Box,
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Container,
  Paper,
  TextField,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkIcon from '@mui/icons-material/Work';
import MessageIcon from '@mui/icons-material/Message';
import SendIcon from '@mui/icons-material/Send';
import getMPTheme from './theme/getMPTheme';
import { PaletteMode, ThemeProvider, createTheme } from '@mui/material/styles';
import FolderIcon from '@mui/icons-material/Folder';
import { Navigate, useNavigate } from 'react-router-dom';

const API_BASE_URL = 'https://backend-summer-butterfly-3214.fly.dev';

const createAxiosInstance = (token = null) => {
  const config = {
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true
  };
  
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  
  return axios.create(config);
};

const createSocketConnection = () => {
  return io(API_BASE_URL, {
    withCredentials: true,
    transports: ['websocket'],
    auth: {
      token: localStorage.getItem('access_token')
    },
    debug: true, // Enable debugging
  });
};

const drawerWidth = 240;

function MessageUser() {
  const { userId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [socket, setSocket] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mode, setMode] = React.useState('light');
  const [chatUsername, setChatUsername] = useState('');
  const [error, setError] = useState(null);

  const MPTheme = createTheme(getMPTheme(mode));
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  useEffect(() => {
    if (userId) {
      const fetchMessages = async () => {
        try {
          const accessToken = localStorage.getItem('access_token');
          if (!accessToken) {
            navigate('/login');
            return;
          }

          const api = createAxiosInstance(accessToken);
          const response = await api.get(`/api/messages`, {
            params: {
              sender_id: localStorage.getItem('userId'),
              recipient_id: userId
            }
          });
          
          setMessages(response.data);
        } catch (error) {
          console.error('Error fetching messages:', error);
          if (error.response) {
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
          }
          setError('Error fetching messages');
        }
      };

      const fetchUsername = async () => {
        try {
          const accessToken = localStorage.getItem('access_token');
          const api = createAxiosInstance(accessToken);
          const response = await api.get(`/api/user/id-to-username/${userId}`);
          setChatUsername(response.data.username);
        } catch (error) {
          console.error('Error fetching username:', error);
          if (error.response) {
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
          }
          setError('Error fetching username');
        }
      };

      fetchMessages();
      fetchUsername();

      const newSocket = createSocketConnection();
      setSocket(newSocket);

      return () => {
        if (newSocket) {
          newSocket.disconnect();
        }
      };
    }
  }, [userId, navigate]);

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }
    if (socket && userId) {
      socket.on('new_message', (message) => {
        if (
          (message.sender_id === userId && message.recipient_id === localStorage.getItem('userId')) ||
          (message.sender_id === localStorage.getItem('userId') && message.recipient_id === userId)
        ) {
          setMessages((prevMessages) => [...prevMessages, message]);
        }
      });
  
      // Handle socket connection errors
      socket.on('connect_error', (error) => {
        console.error('Socket connection error:', error);
        setError('Error connecting to chat service');
      });
  
      return () => {
        socket.off('new_message');
      };
    }
  }, [socket, userId]);

  const handleSendMessage = async () => {
    if (newMessage.trim() !== '' && userId) {
      try {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
          navigate('/login');
          return;
        }
  
        const message = {
          sender_id: localStorage.getItem('userId') || '',
          recipient_id: userId,
          content: newMessage,
          timestamp: new Date(),
        };
  
        // Emit the message to the server via socket
        if (socket) {
          socket.emit('new_message', message);
        }
  
        // Send the message via API for persistent storage
        const api = createAxiosInstance(accessToken);
        const response = await api.post('/api/messages', message);
        setMessages(prevMessages => [...prevMessages, response.data]);
        setNewMessage('');
      } catch (error) {
        console.error('Error sending message:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        }
        setError('Error sending message');
      }
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {[
          { text: 'Dashboard', icon: <DashboardIcon />, route: '/dashboard' },
          { text: 'Jobs', icon: <WorkIcon />, route: '/jobs' },
          { text: 'Job Post', icon: <WorkIcon />, route: '/job-post' },

          { text: 'Messages', icon: <MessageIcon />, route: '/messaging' },
          { text: 'Folders', icon: <FolderIcon />, route: '/folders' },
        ].map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton onClick={() => handleNavigation(item.route)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  if (!userId) {
    return <Typography>User ID not provided.</Typography>;
  }

  return (
    <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Chat with {chatUsername || 'Loading...'}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 'calc(100vh - 200px)' }}>
              <Typography variant="h6" gutterBottom>
                Chatting with: {chatUsername || 'Loading...'}
              </Typography>
              {error && <Typography color="error">{error}</Typography>}
              <Box sx={{ flexGrow: 1, overflow: 'auto', mb: 2 }}>
                {messages
                  .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
                  .map((message) => (
                    <Box 
                      key={message._id} 
                      sx={{ 
                        mb: 1, 
                        textAlign: message.sender_id === localStorage.getItem('userId') ? 'right' : 'left' 
                      }}
                    >
                      <Paper 
                        elevation={1} 
                        sx={{ 
                          display: 'inline-block', 
                          p: 1, 
                          bgcolor: message.sender_id === localStorage.getItem('userId') ? 'primary.light' : 'grey.200'
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          {message.sender_id === localStorage.getItem('userId') ? 'You' : chatUsername}
                        </Typography>
                        <Typography variant="body1">
                          {message.content}
                        </Typography>
                      </Paper>
                    </Box>
                  ))}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Type your message..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                  onClick={handleSendMessage}
                  sx={{ ml: 1 }}
                >
                  Send
                </Button>
              </Box>
            </Paper>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default MessageUser;
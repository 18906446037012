import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  CardActions,
  Button,
  Container,
  Grid,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkIcon from '@mui/icons-material/Work';
import MessageIcon from '@mui/icons-material/Message';
import PeopleIcon from '@mui/icons-material/People';
import getMPTheme from './theme/getSignInSideTheme';
import { PaletteMode, ThemeProvider, createTheme } from '@mui/material/styles';
import FolderIcon from '@mui/icons-material/Folder';

const API_BASE_URL = 'https://backend-summer-butterfly-3214.fly.dev';

const createAxiosInstance = (token = null) => {
  const config = {
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true
  };
  
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  
  return axios.create(config);
};

const drawerWidth = 240;

function Jobs() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [applications, setApplications] = useState([]);
  const [mode, setMode] = React.useState('light');

  const MPTheme = createTheme(getMPTheme(mode));
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode } });

  const navigate = useNavigate();
  const handleNavigation = (route) => {
    navigate(route);
  };

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }

    const checkRole = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
          navigate('/login');
          return;
        }

        const api = createAxiosInstance(accessToken);
        const userInfoResponse = await api.get('/api/user-info');
        
        const userRole = userInfoResponse.data.role;
        
        if (userRole === 'client') {
          navigate('/dashboard');
          return;
        }
      } catch (error) {
        console.error('Error checking role:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        }
        navigate('/login');
      }
    };

    const fetchApplications = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        const api = createAxiosInstance(accessToken);
        const response = await api.get('/api/client/query_applications');
        setApplications(response.data);
      } catch (error) {
        console.error('Error fetching applications:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        }
      }
    };

    checkRole();
    fetchApplications();
  }, [navigate]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleInterest = (applicationId) => {
    console.log(applicationId, 'ni');
    navigate(`/job_application/${applicationId}`);
  };

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {[
          { text: 'Dashboard', icon: <DashboardIcon />, route: '/dashboard' },
          { text: 'Jobs', icon: <WorkIcon />, route: '/jobs' },
          { text: 'Job Post', icon: <WorkIcon />, route: '/job-post' },

          { text: 'Messages', icon: <MessageIcon />, route: '/messaging' },
          { text: 'Folders', icon: <FolderIcon />, route: '/folders' },
        ].map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton onClick={() => handleNavigation(item.route)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Jobs
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" gutterBottom>
              Available Jobs
            </Typography>
            <Grid container spacing={3}>
              {applications.map((application) => (
                <Grid item xs={12} md={6} lg={4} key={application._id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {application.jobTitle}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" paragraph>
                        {application.jobDescription}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Company:</strong> {application.name}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Location:</strong> {application.address}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button 
                        size="small" 
                        color="primary" 
                        onClick={() => handleInterest(application._id)}
                      >
                        Interested
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Jobs;